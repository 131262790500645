<template>
  <div class="reportOptions">
    <lenon-list-page
        title="Report Card Options"
        :columns="columns"
        :rows="reportOptions"
        :table-loading="tableLoading"
        :show-create="false"
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start mb-2 desktop-only">
          <lenon-button
              icon="SettingsIcon"
              label="Report Options"
              tool-tip-text="Manage Report Cards Options(CRUD)"
              v-responsive="$store.getters['app/largeScreen']"
              @onClick="showReportCardSetupModal()"
          />
        </div>
        <div class="d-flex align-self-center align-items-start mb-2" v-responsive="$store.getters['app/smallScreen']">
          <lenon-dropdown left>
            <lenon-dropdown-item
                icon="SettingsIcon"
                text="Report Options"
                @click="showReportCardSetupModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
      <template #options="{row}">
        <b-badge variant="light-success" class="mr-1" v-for="(op,i) in row.item.options" :key="i">
          {{
            op.replaceAll('-', ' ')
                .toUpperCase()
          }}
        </b-badge>
      </template>
      <template #classes="{row}">
        <b-badge variant="light-success" class="mr-1" v-for="(op,i) in getClasses(row.item.classes)" :key="i">
          {{ op }}
        </b-badge>
      </template>
    </lenon-list-page>

    <report-option-setup
        :modal-opened="reportSetupModalOpened"
        @modalClosed="closeModal('reportSetup')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import ReportOptionSetup from '@/views/admin/academics/report-card/ReportOptionSetup.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { BBadge } from 'bootstrap-vue'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import LenonDropdown from '@/lenon/components/LenonDropdown'

export default {
  name: 'ReportCardList',
  components: {
    LenonDropdown,
    LenonDropdownItem,
    LenonListPage,
    LenonSelect,
    ReportOptionSetup,
    LenonButton,
    BBadge,
  },
  mixins: [showToast],
  data() {
    return {
      reportSetupModalOpened: false,
      tableLoading: false,
      selectedReportCardId: null,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'options',
          label: 'Hidden Report Fields',
        },
        {
          key: 'classes',
          label: 'Affected Classes',
        },
      ],
    }
  },
  computed: {
    reportOptions() {
      return this.$store.getters['reportOptions/reportOptions']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
  },
  mounted() {
    this.fetchReportCards()
  },
  methods: {
    getClasses(clids) {
      return this.classes.filter((cl) => clids.includes(cl.id))
          .map((c) => c.name) || []
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'reportSetup':
          this.reportSetupModalOpened = false
          break
      }
    },
    showReportCardSetupModal() {
      this.reportSetupModalOpened = true
    },
    fetchReportCards() {
      this.$http.get('academics/report-options')
          .then(res => {
            this.$store.commit('reportOptions/setReportOptions', res.data)
          })
    },
  },
}
</script>
