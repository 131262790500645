<template>
  <!--    ReportOption Form-->
  <validation-observer
      ref="reportOptionForm"
      v-slot="{invalid}"
  >
    <lenon-modal
        :title="`${updateReportOptionMode?'Update':'Create'} Report Option`"
        :show="reportOptionModalOpened"
        :show-overlay="deletingReportOption"
        size="md"
        @onClose="closeReportOptionModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
              v-model="selectedReportOptionId"
              placeholder="All Report Options"
              :options="reportOptions"
              label-name="title"
              value-name="id"
              @input="populateReportOptionForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
              icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteReportOption()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetReportOptionForm()"
          />
        </div>
      </b-row>
      <error-display :error="error"/>
      <lenon-input
          v-model="reportOption.title"
          name="title"
          placeholder="Enter option title, eg. Primary Report Options,etc."
          rules="required"
          label="Option Title"
      />
      <div class="mt-2">
        <lenon-multi-select
            v-model="reportOption.options"
            name="options"
            value-name="id"
            label-name="name"
            placeholder="Select Fields to Hide"
            :options="options"
            rules="required"
            label="Hidden Fields"
        />
      </div>
      <div class="mt-2 mb-5">
        <lenon-multi-select
            v-model="reportOption.classes"
            name="classes"
            value-name="id"
            label-name="name"
            placeholder="Select classes affected"
            :options="classes"
            rules="required"
            label="Affected Classes"
        />
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeReportOptionModal()"
          />
          <lenon-button
              :label="updateReportOptionMode?'Update':'Create'"
              :disabled="invalid"
              :loading="reportOptionLoading"
              loading-text="Loading..."
              @onClick="updateReportOptionMode?updateReportOption():createReportOption()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import { CREATE_SUBJECT_M, DELETE_SUBJECT_M, UPDATE_SUBJECT_M } from '@/graphql/mutations'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect'

export default {
  name: 'SchoolReportOption',
  components: {
    LenonMultiSelect,
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      error: {},
      reportOptionModalOpened: false,
      updateReportOptionMode: false,
      reportOptionLoading: false,
      deletingReportOption: false,
      selectedReportOptionId: null,
      reportOption: {
        id: null,
        title: null,
        classes: [],
        options: [],
      },
      options: [
        {
          id: 'overall-position',
          name: 'Overall Position'
        },
        {
          id: 'subject-position',
          name: 'Subject Position'
        },
        {
          id: 'subject-grade',
          name: 'Subject Grade'
        },
        {
          id: 'highest-score',
          name: 'Highest Score'
        },
        {
          id: 'grade-remarks',
          name: 'Grade Remarks'
        },
        {
          id: 'teacher-remarks',
          name: 'Class Teacher Remarks'
        },
        {
          id: 'student-conduct',
          name: 'Student Conduct'
        },
        {
          id: 'student-talents',
          name: 'Student Talents'
        },
        {
          id: 'student-interests',
          name: 'Student Interests'
        },
        {
          id: 'head-teacher-remarks',
          name: 'Head Master Remarks'
        },
        {
          id: 'student-attendance',
          name: 'Student Attendance'
        },
      ],
    }
  },
  computed: {
    selectedReportOption() {
      return this.reportOptions.find(ter => ter.id === this.selectedReportOptionId)
    },
    reportOptions() {
      return this.$store.getters['reportOptions/reportOptions']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
  },
  watch: {
    modalOpened(opened) {
      this.reportOptionModalOpened = opened
    },
  },
  methods: {
    // reportOption
    resetReportOptionForm() {
      this.reportOption = {
        id: null,
        title: null,
        classes: [],
        options: [],
      }
      this.selectedReportOptionId = null
      this.updateReportOptionMode = false
      this.$refs.reportOptionForm.reset()
    },
    populateReportOptionForm(sub) {
      if (this.selectedReportOption) {
        this.updateReportOptionMode = true
        this.reportOption = {
          ...this
              .selectedReportOption,
          classes: this.selectedReportOption.classes.map((id) => this.classes.find((c) => c.id === id)),
          options: this.selectedReportOption.options.map((id) => this.options.find((c) => c.id === id)),
        }
      } else {
        this.resetReportOptionForm()
      }
    },
    closeReportOptionModal() {
      this.reportOptionModalOpened = false
      this.$emit('modalClosed')
    },
    updateReportOption() {
      this.error = {}
      if (!this.reportOption.id) {
        return
      }
      const classes = this.reportOption.classes.map((c) => c.id)
      const options = this.reportOption.options.map((c) => c.id)
      this.reportOptionLoading = true
      this.$http.put(`academics/report-options/${this.selectedReportOptionId}`, {
        ...this
            .reportOption,
        classes,
        options
      })
          .then(res => {
            this.showSuccess('Updated report option successfully')
            this.reportOptionLoading = false
            this.$store.commit('reportOptions/updateReportOption', res.data)
          })
          .catch(err => {
            logData(err)
            this.error = err
            this.showError('Failed to update reportOption')
          }).finally(()=>{
        this.reportOptionLoading = false
      })
    },
    createReportOption() {
      this.error = {}
      const classes = this.reportOption.classes.map((c) => c.id)
      const options = this.reportOption.options.map((c) => c.id)
      this.reportOptionLoading = true
      this.$http.post('academics/report-options', {
            ...this
                .reportOption,
            classes,
            options
          }
      )
          .then(res => {
            this.showSuccess('Created report option successfully')
            this.reportOptionLoading = false
            this.$store.commit('reportOptions/addReportOption', res.data)
            this.resetReportOptionForm()
          })
          .catch(err => {
            logData(err)
            this.error = err
            this.showError('Failed to create report option')
            this.reportOptionLoading = false
          }).finally(()=>{
        this.reportOptionLoading = false
      })
    },
    deleteReportOption() {
      if (!this.selectedReportOptionId) {
        this.showInfo('Please select a report option')
        return
      }
      this.deletingReportOption = true
      this.$http.delete(`academics/report-options/${this.selectedReportOptionId}`)
          .then(() => {
            this.deletingReportOption = false
            this.showSuccess('Deleted Successfully')
            this.$store.commit('reportOptions/removeReportOption', this.selectedReportOptionId)
            this.selectedReportOptionId = null
            this.resetReportOptionForm()
          })
          .catch(() => {
            this.showError('Failed to delete report option')
            this.deletingReportOption = false
          }).finally(()=>{
        this.reportOptionLoading = false
      })
    },

  },
}
</script>
